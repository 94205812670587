import {Pipe, PipeTransform} from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'pastTime'
})
export class PastTimePipe implements PipeTransform {
  transform(value: Date | string): unknown {
    return dayjs().to(dayjs(value));
  }
}
