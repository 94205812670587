<div class="d-flex w-100 h-100" style="background: var(--gray-50, #F6F6F6);">
  <div class="sidebar">
    <div class="row">
      <div class="col-12 px-4">
        <div class="d-flex flex-column justify-content-between align-items-center gap-2">
          <div class="logo-container align-self-center my-5">
            <img src="{{cdnUri('assets/logo/nks-dark.svg')}}" alt="Logo" width="150" height="100">
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 px-4">
        <ng-container *ngFor="let p of appPages; let i = index;">
          <div class="nav-item d-flex w-100 align-items-center p-3 color-gray-300 {{i === 0 ? 'home' : ''}}"
               style="gap: 10px;" [routerLink]="p.url" routerLinkActive="selected">
            <gray-icon icon="{{p.icon}}"></gray-icon>
            <p class="fs-large fw-500">{{p.title}}</p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="content-wrapper d-flex flex-column w-100 h-100 p-4 overflow-y-scroll">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
