<div (click)="click()"
  [class]="'fw-600 level-select-item clickable mb-2 p-2 ' +  'border-level-' + level.key + '-500 ' + ((isSelected) ? ('color-gray-50 bg-level-' + level.key + '-500') : ('color-level-' + level.key + '-500 hover-bg-level-' + level.key + '-500'))  ">
  <span class="fw-800">{{('levels.' + level.key) |translate}}</span>

  <span class="fs-small" *ngIf="!score?.minScore">
      {{score?.maxScore}}' den az
    </span>
  <span class="fs-small" *ngIf="!score?.maxScore">
      {{score?.minScore}} ve üzeri
    </span>
  <span class="fs-small" *ngIf="score?.maxScore && score?.minScore">
      {{score?.minScore}}-{{score?.maxScore}}
    </span>

</div>
