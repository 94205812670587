import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ILevel, ILevelExamScore } from "../../../../../core/models/user/level.model";

@Component({
  selector: 'app-level-selector',
  templateUrl: './level-selector.component.html',
  styleUrl: './level-selector.component.scss'
})
export class LevelSelectorComponent implements OnInit {
  @Input() levels: ILevel[] = [];
  @Input() selectedLevelId?: number;
  @Input() selectedExamId?: number;
  @Output() selected = new EventEmitter();

  levelScores: (ILevel & { selectedScore?: ILevelExamScore })[] = []

  constructor() {
  }

  ngOnInit() {
    this.levelScores = this.levels.map(l => {
      const score = l.examScores?.find(a => a.examId === this.selectedExamId);
      return {
        ...l,
        selectedScore: score,
      }
    });
  }

  selectLevel(level: ILevel) {
    this.selected.emit(level);
  }
}
