import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from "rxjs";

@Component({
  selector: 'app-emoji-picker-popup',
  templateUrl: './emoji-picker-popup.component.html',
  styleUrl: './emoji-picker-popup.component.scss'
})
export class EmojiPickerPopupComponent implements OnInit, OnDestroy {
  @Input() placement = 'end';
  @Input() btnTheme = 'light';
  @Output() onSelect = new EventEmitter();

  emojiI18n = {
    search: 'Arama',
    emojilist: 'Emoji Listesi',
    notfound: 'Emoji Bulunamadı',
    clear: 'Temizle',
    categories: {
      search: 'Arama Sonuçları',
      recent: 'En Çok Kullanılanlar',
      people: 'Gülümsemeler & İnsanlar',
      nature: 'Hayvanlar & Doğa',
      foods: 'Yiyecek & İçecek',
      activity: 'Etkinlik',
      places: 'Seyehat & Yerler',
      objects: 'Objeler',
      symbols: 'Semboller',
      flags: 'Bayraklar',
      custom: 'Özel',
    },
  };
  destroyed$ = new Subject();

  constructor() {
  }

  ngOnInit() {
  }

  onEmojiSelect(event: any) {
    const emoji: string = (event.emoji as any).native;
    this.onSelect.emit(emoji);
  }

  emojiFilter(event: any) {
    return ![
      '1F972', '1F978', '1FAC0', '1FAC1', '1F90C', '1F9AC',
      '1F9A3', '1F9AB', '1F9A4', '1FAB6', '1F9AD', '1FAB2',
      '1FAB1', '1FAB0', '1FAB3', '1FAB4', '1FAD0', '1FAD2',
      '1FAD1', '1FAD3', '1FAD5', '1FAD4', '1FAD6', '1F9CB',
      '1FA84', '1FA86', '1FA85', '1FAA2', '1FAA1', '1F6D6',
      '1FAB5', '1FAA8', '1F6FC', '1F6FB', '1FA96', '1FA98',
      '1FA97', '1FA9D', '1FA9B', '1FA9A', '1FA83', '1FA9C',
      '1F6D7', '1FA9E', '1FA9F', '1FAA4', '1FAA5', '1FAA3',
      '1FAA0', '1FAA7', '1FAA6', '1FAE0', '1FAE2', '1FAE3',
      '1FAE1', '1FAE5', '1FAE4', '1F979', '1FAF1', '1FAF2',
      '1FAF3', '1FAF4', '1FAF0', '1FAF5', '1FAF6', '1FAE6',
      '1F977', '1FAC5', '1FAC3', '1FAC4',
    ].includes(event);
  }


  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
