import { NgModule } from '@angular/core';
import { NgbDateParserFormatter, NgbDatepickerModule, NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';

import { GrayTextInputComponent } from './components/inputs/gray-text-input/gray-text-input.component';
import { GrayPasswordInputComponent } from './components/inputs/gray-password-input/gray-password-input.component';
import { GrayButtonComponent } from './components/buttons/gray-button/gray-button.component';
import { GrayCheckboxComponent } from './components/inputs/gray-checkbox/gray-checkbox.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GrayWidgetOneComponent } from './components/widgets/gray-widget-one/gray-widget-one.component';
import { GrayWeeklyPlanComponent } from './components/gray-weekly-plan/gray-weekly-plan.component';
import { GrayIconComponent } from './components/gray-icon/gray-icon.component';
import { NgxMaskDirective, NgxMaskPipe } from "ngx-mask";
import { GraySelectInputComponent } from './components/inputs/gray-select-input/gray-select-input.component';
import { NgSelectModule } from "@ng-select/ng-select";
import {
  GrayDatePickerInputComponent
} from './components/inputs/gray-date-picker-input/gray-date-picker-input.component';
import {
  NgbDateCustomParserFormatter
} from "./components/inputs/gray-date-picker-input/ngb-date-custom-parser-formatter";
import { UserProfileImgComponent } from './components/user-profile-img/user-profile-img.component';
import { BePremiumModalComponent } from './components/be-premium-modal/be-premium-modal.component';
import { TranslateModule } from "@ngx-translate/core";
import { StaticVideoPlayerComponent } from "./components/static-video-player/static-video-player.component";
import { EmojiPickerPopupComponent } from './components/emoji-picker-popup/emoji-picker-popup.component';
import { PickerComponent } from "@ctrl/ngx-emoji-mart";
import { GrayRadioComponent } from './components/inputs/gray-radio/gray-radio.component';
import { LevelSelectorComponent } from './components/level-selector/level-selector.component';
import { LevelSelectItemComponent } from './components/level-selector/level-select-item/level-select-item.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,

    NgxMaskDirective,
    NgxMaskPipe,
    NgSelectModule,
    NgbDatepickerModule,
    TranslateModule,
    PickerComponent,
    NgbPopoverModule,
  ],
  providers: [
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateCustomParserFormatter,
    }
  ],
  declarations: [
    GrayTextInputComponent,
    GrayPasswordInputComponent,
    GrayButtonComponent,
    GrayCheckboxComponent,
    GrayWidgetOneComponent,
    GrayWeeklyPlanComponent,
    GrayIconComponent,
    GraySelectInputComponent,
    GrayDatePickerInputComponent,
    UserProfileImgComponent,
    BePremiumModalComponent,
    StaticVideoPlayerComponent,
    EmojiPickerPopupComponent,
    GrayRadioComponent,
    LevelSelectorComponent,
    LevelSelectItemComponent,
  ],
  exports: [
    GrayTextInputComponent,
    GrayPasswordInputComponent,
    GrayButtonComponent,
    GrayCheckboxComponent,
    GrayWidgetOneComponent,
    GrayWeeklyPlanComponent,
    GrayIconComponent,
    GraySelectInputComponent,
    GrayDatePickerInputComponent,
    UserProfileImgComponent,
    BePremiumModalComponent,
    StaticVideoPlayerComponent,
    EmojiPickerPopupComponent,
    GrayRadioComponent,
    LevelSelectorComponent,
    LevelSelectItemComponent
  ],
})
export class ThemeModule {
}
