import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-gray-date-picker-input',
  templateUrl: './gray-date-picker-input.component.html',
  styleUrl: './gray-date-picker-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: GrayDatePickerInputComponent
    },
  ]
})
export class GrayDatePickerInputComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() formControlName!: string; // <-- passing value from parent component
  readonly formControl: FormControl = new FormControl(); // <-- local FormControl
  @Input() placeholder: string = '';
  @Input() maxDate?: Date;
  @Input() minDate?: Date;
  @Input() hasError = false;
  @Input() theme: 'default' | 'transparent' | 'mini' = 'default';
  @Input() inputCustomClass = '';
  @Input() value: any = null;
  @Input() navigation: 'arrows' | 'select' = 'arrows';
  @Output() valueChange = new EventEmitter<any>(); // Add this line

  touched = false;
  disabled = false;
  private readonly destroyRef: DestroyRef = inject(DestroyRef); // <-- used for unsubscribing

  onChange = (value: any) => {
  };

  onTouched = () => {
  };

  ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val: any): void => {
        this.onChange(val);
      }); // <-- passing value to the parent form
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes?.value?.currentValue) {
      const date = (changes.value.currentValue instanceof Date) ? changes.value.currentValue : new Date(changes.value.currentValue);

      const val = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
      };

      this.value = val;
      this.writeValue(val);
    } else if (changes?.value?.currentValue === null) {
      this.value = null;
      this.writeValue(null);
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  onValueChange(e: Event & any) {
    const value: any = e.target.value;

    this.markAsTouched();
    if (!this.disabled) {
      this.value = value;
      this.onChange(this.value);
      this.valueChange.emit(this.value); // Emit the value change
    }
  }

  modelValChange(value: any) {
    this.markAsTouched();
    if (!this.disabled) {
      this.valueChange.emit(value); // Emit the value change
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  writeValue(value: any): void {
    this.formControl.patchValue(value, {emitEvent: false});
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    isDisabled ? this.formControl.disable() : this.formControl.enable();
  }
}
