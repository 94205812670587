<div class="card">
  <div class="card-header">
    <div class="d-flex justify-content-between">
      <h2 class="fs-xlarge fw-800 color-gray-950">Haftalık Planım</h2>

      <div class="indicators d-inline-flex justify-content-between align-items-center" style="gap: 10px">
        <p class="fs-small fw-400 color-gray-950"><gray-icon icon="circle-filled"></gray-icon> Haftalık</p>
        <p class="fs-small fw-400 color-gray-950"><gray-icon icon="circle-filled"></gray-icon> Genel</p>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-7">
        <div class="row mb-2">
          <div class="col-4">
            <app-gray-widget-one bgColor="pink" icon="calendar" subject="Takvim" value="4.Hafta"></app-gray-widget-one>
          </div>
          <div class="col-4">
            <app-gray-widget-one bgColor="pink" icon="rocket" subject="İlerleme" value="%60"></app-gray-widget-one>
          </div>
          <div class="col-4">
            <app-gray-widget-one bgColor="pink" icon="book-open" subject="Konular" value="24 Konu"></app-gray-widget-one>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-4">
            <app-gray-widget-one bgColor="green" icon="handshake" subject="Tamamlanan" value="2/10"></app-gray-widget-one>
          </div>
          <div class="col-4">
            <app-gray-widget-one bgColor="green" icon="agenda" subject="Kalan Zaman" value="145 Gün"></app-gray-widget-one>
          </div>
          <div class="col-4">
            <app-gray-widget-one bgColor="green" icon="tents" subject="Kalan Plan" value="%64"></app-gray-widget-one>
          </div>
        </div>
      </div>
      <div class="col-5">
        <div class="row mb-2">
          <div class="col-12">
            <div class="data-item weekly-statistics d-flex flex-column justify-content-center">
              <p class="fs-normal fw-800 color-gray-950">İstatistik</p>

              <div class="d-block w-100 h-100">
                <div class="chart-container" style="width: auto;height: 225px;"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--<div class="row mb-2">
      <div class="col-12">
        <app-gray-button color="primary" customClass="bg-linear-pink" text="Haftalık Planım'a Git" postIcon="arrow-right"></app-gray-button>
      </div>
    </div>-->
  </div>
</div>
