<div class="input-container {{theme}}" #inputContainer [class.has-error]="hasError">
  <div class="input-group">
    <input
      #inputElement
      class="form-control {{inputCustomClass}}"
      autocapitalize="off"
      autocomplete="off"
      (focusin)="inputContainer.classList.add('active')"
      (focusout)="inputContainer.classList.remove('active')"
      [placeholder]="placeholder"
      [maxDate]="maxDate ? {year: maxDate.getFullYear(), month: maxDate.getMonth() + 1, day: maxDate.getDate()}: undefined"
      [minDate]="minDate ? {year: minDate.getFullYear(), month: minDate.getMonth() + 1, day: minDate.getDate()}: undefined"
      (click)="d.toggle();"
      (input)="onValueChange($event)"
      (dateSelect)="modelValChange($event)"
      [formControl]="formControl"
      [id]="formControlName"
      [autoClose]="true"
      [navigation]="navigation"
      [container]="'body'"
      ngbDatepicker
      #d="ngbDatepicker"
    />
  </div>

</div>
