import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: 'relativeTimeAfter'
})
export class RelativeTimeAfterPipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ) {
  }

  transform(value: Date): string {
    if (!(value instanceof Date))
      value = new Date(value);

    let seconds: number = Math.floor((value.getTime() - (new Date()).getTime()) / 1000);
    let interval: number = Math.floor(seconds / 31536000);

    const after = this.translateService.instant('times.after');
    const second = this.translateService.instant('times.second');
    const minute = this.translateService.instant('times.minute');
    const day = this.translateService.instant('times.day');
    const year = this.translateService.instant('times.year');
    const month = this.translateService.instant('times.month');
    const hour = this.translateService.instant('times.hour');

    if (interval > 1) {
      return interval + ` ${year} ${after}`;
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + ` ${month} ${after}`;
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + ` ${day} ${after}`;
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + ` ${hour} ${after}`;
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + ` ${minute} ${after}`;
    }
    return Math.floor(seconds) + ` ${second} ${after}`;
  }

}
