<!--<video *ngIf="elementId && videoSrc && poster"-->
<!--  [id]="elementId" class="video-js vjs-default-skin"  controls preload="none"-->
<!--  [poster]='poster' [controls]="hasControls"-->
<!--  data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }'>-->
<!--  <source [src]="videoSrc" type='video/mp4'/>-->
<!--</video>-->
<!--<video *ngIf="elementId && videoSrc && (!poster)"-->
<!--       [id]="elementId" class="video-js vjs-default-skin"  controls-->
<!--       [controls]="hasControls"-->
<!--       data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }'>-->
<!--  <source [src]="videoSrc" type='video/mp4'/>-->
<!--</video>-->

<div class="player-container" id="player-container"></div>

<!--<button (click)="playPause()">Play/Pause</button>-->
<!--<button (click)="makeBig()">Big</button>-->
<!--<button (click)="makeSmall()">Small</button>-->
<!--<button (click)="makeNormal()">Normal</button>-->
<!--<button (click)="restart();">Restart</button>-->
<!--<button (click)="skip(-10)">Rewind</button>-->
<!--<button (click)="skip(10)">Fastforward</button>-->
