import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'relativeTime'
})
export class RelativeTimePipe implements PipeTransform {

  constructor(
    private translateService: TranslateService
  ) {
  }

  transform(value: Date): string {
    if (!(value instanceof Date))
      value = new Date(value);

    let seconds: number = Math.floor(((new Date()).getTime() - value.getTime()) / 1000);
    let interval: number = Math.floor(seconds / 31536000);

    const ago = this.translateService.instant('times.ago');
    const second = this.translateService.instant('times.second');
    const minute = this.translateService.instant('times.minute');
    const day = this.translateService.instant('times.day');
    const year = this.translateService.instant('times.year');
    const month = this.translateService.instant('times.month');
    const hour = this.translateService.instant('times.hour');

    if (interval > 1) {
      return interval + ` ${year} ${ago}`;
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + ` ${month} ${ago}`;
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + ` ${day} ${ago}`;
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + ` ${hour} ${ago}`;
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + ` ${minute} ${ago}`;
    }
    return Math.floor(seconds) + ` ${second} ${ago}`;
  }

}
