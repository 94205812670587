<button
  type="button" class="btn emoji-select-btn {{btnTheme}}"
  [autoClose]="'outside'" #popover="ngbPopover"
  [ngbPopover]="popContent" [placement]="placement" popoverClass="emoji-picker-popover">
  <gray-icon icon="smile"></gray-icon>
</button>


<ng-template #popContent>
  <emoji-mart
    title=""
    [showPreview]="false" [i18n]="emojiI18n"
    locale="tr"
    theme="light" [isNative]="true"
    [exclude]="['recent', 'flags']"
    [enableSearch]="false" [skin]="2"
    [showSingleCategory]="true" [hideObsolete]="true"
    [emojiTooltip]="false" [enableFrequentEmojiSort]="false"
    [emojisToShowFilter]="emojiFilter"
    (emojiSelect)="onEmojiSelect($event); popover.close();"></emoji-mart>
</ng-template>
