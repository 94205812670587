import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDuration'
})
export class FormatDurationPipe implements PipeTransform {

  transform(value: number): string {
    if (!value) return '0 dk';

    const hours = Math.floor(value / 60);
    const minutes = value % 60;

    const hoursText = hours > 0 ? `${hours} s.` : '';
    const minutesText = minutes > 0 ? `${minutes} dk` : '';

    return `${hoursText} ${minutesText}`.trim();
  }

}
