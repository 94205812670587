import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-be-premium-modal',
  templateUrl: './be-premium-modal.component.html',
  styleUrl: './be-premium-modal.component.scss'
})
export class BePremiumModalComponent {
  constructor(
    public modal: NgbActiveModal,
    private router: Router
  ) {
  }
  goToPayment() {
    this.router.navigateByUrl('/payment');
    this.modal.close();
  }
}
