<!--
<div class="checkbox-container fs-normal color-gray-400 d-flex align-items-center">
  <input #checkbox style="display: none;" type="checkbox" class="checkbox" [value]="value" [checked]="value" (change)="onValueChange($event)">

  <gray-icon class="me-2 clickable" icon="{{!value ? 'circle' : 'circle-filled-bordered'}}" (click)="checkbox.click()"></gray-icon>

  <p class="fw-350" (click)="checkbox.click()"><ng-content></ng-content></p>
</div>
-->

<div class="checkbox-container d-flex align-items-center gap-2 form-check fs-normal color-gray-400">
  <input #checkbox type="checkbox" class="form-check-input checkbox" [value]="value" [checked]="value" (change)="onValueChange($event)">

  <!--<gray-icon class="me-2" icon="{{!value ? 'circle' : 'circle-filled'}}" (click)="checkbox.click()"></gray-icon>-->

  <label class="form-check-label" (click)="checkbox.click()"><ng-content></ng-content></label>
</div>

