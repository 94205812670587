<div class="checkbox-container fs-normal color-gray-400 d-flex align-items-center">
  <input #checkbox  style="display: none;" type="checkbox" class="checkbox" [value]="value" [checked]="value"
         (change)="onValueChange($event)">

  <gray-icon class="me-2 clickable" icon="{{!value ? 'circle' : 'circle-filled-bordered'}}"
             (click)="checkbox.click()"></gray-icon>

  <p class="fw-350" (click)="checkbox.click()">
    <ng-content></ng-content>
  </p>
</div>
