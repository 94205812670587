<ng-select
  [placeholder]="placeholder"
  [items]="items"
  (change)="onValueChange($event)"
  [multiple]="false"
  [disabled]="disabled"
  [class]="customClass"
  [class.has-error]="hasError"
  [(ngModel)]="value"
  [bindLabel]="bindLabel"
  [clearable]="clearable"
  [bindValue]="bindValue"
  [searchFn]="searchFn"
>
</ng-select>
