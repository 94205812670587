import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'gray-icon',
  templateUrl: './gray-icon.component.html',
  styleUrls: ['./gray-icon.component.scss'],
})
export class GrayIconComponent implements OnInit {
  @Input() icon!: (
    'user' |
    'phone' |
    'lock' |
    'mail' |
    'eye' |
    'eye-slash' |
    'premium' |
    'arrow-right' |
    'circle' |
    'circle-filled' |
    'basketball' |
    'document-plus' |
    'pie-chart' |
    'calendar' |
    'rocket' |
    'book-open' |
    'handshake' |
    'agenda' |
    'tents' |
    'search' |
    'home' |
    'videos' |
    'analysis' |
    'blog' |
    'document' |
    'brain' |
    'know-yourself' |
    'pin'
    ) | string;

  constructor() {
  }

  ngOnInit() {
  }

}
