import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BePremiumModalComponent } from "../modules/theme/components/be-premium-modal/be-premium-modal.component";

@Injectable()
export class PremiumAccessService {
  constructor(
    private modalService: NgbModal,
  ) {
  }

  openPremiumContentModal() {
    this.modalService.open(BePremiumModalComponent, {
      size: 'md',
      keyboard: false,
      backdrop: 'static',
      animation: true,
      centered: true,
    });
  }
}
