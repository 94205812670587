<div #inputContainer class="input-container {{customClass}}" [class.disabled]="disabled" [class.has-error]="hasErr">
  <div class="input-group">
    <div class="input-group-text" *ngIf="icon && icon !== ''">
      <gray-icon [icon]="icon"></gray-icon>
    </div>
    <input type="text" class="form-control fs-large"
           [type]="type"
           autocapitalize="off"
           autocomplete="off"
           [mask]="mask"
           [prefix]="maskPrefix"
           [formControl]="formControl"
           [dropSpecialCharacters]="dropSpecialCharacters"
           [id]="formControlName"
           (focusin)="inputContainer.classList.add('active')"
           (focusout)="inputContainer.classList.remove('active')"
           [placeholder]="placeholder" />
<!--           (input)="onValueChange($event)">-->
    <!--(change)="onValueChange($event)">-->
  </div>
</div>
