import { Component, Input } from '@angular/core';
import { DefaultProfilePhoto } from "../../../../../core/constants/profile-photo.constant";

@Component({
  selector: 'app-user-profile-img',
  templateUrl: './user-profile-img.component.html',
  styleUrl: './user-profile-img.component.scss'
})
export class UserProfileImgComponent {
  @Input() image?: string;
  @Input() width = '55px';
  @Input() height = '55px';
  @Input() hasLevel = false;
  defaultImg = DefaultProfilePhoto;
}
