import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'app-gray-radio',
  templateUrl: './gray-radio.component.html',
  styleUrl: './gray-radio.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: GrayRadioComponent,
    },
  ]
})
export class GrayRadioComponent implements ControlValueAccessor {
  @Input() value = false;

  touched = false;
  disabled = false;

  onChange = (value: any) => {
  };

  onTouched = () => {
  };

  onValueChange($event: Event & any) {
    const value: any = $event.target.checked;

    this.markAsTouched();
    if (!this.disabled) {
      this.value = value;
      this.onChange(this.value);
    }
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}
