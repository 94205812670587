import { Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { environment } from "../../../../../../environments/environment";
import { Flowplayer, Player } from "@flowplayer/player";
import { isPlatformBrowser } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";

declare var flowplayer: Flowplayer.FlowplayerUMD;

@Component({
  selector: 'app-static-video-player',
  templateUrl: './static-video-player.component.html',
  styleUrl: './static-video-player.component.scss'
})
export class StaticVideoPlayerComponent implements OnInit {

  @Input() poster?: string;
  @Input() videoSrc: string = '';
  @Input() hasControls = true;

  isBrowser: boolean;
  player?: Player;

  elementId?: string;
  isPlay: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private sanitizer: DomSanitizer,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.elementId = `video-${(Math.random() + 1).toString(36).substring(7)}`;
    this.initPlayer();
  }

  async initPlayer() {
    if ((!this.videoSrc) && !this.isBrowser) {
      return;
    }
    this.sanitizer.bypassSecurityTrustResourceUrl(this.videoSrc);
    this.player = flowplayer('#player-container', {
      controls: true,
      token: environment.flowPlayerToken,
      hls: {
        // xhrSetup: (xhr: any) => {
        //   xhr.setRequestHeader('X-GK-Token', environment.videoProxyToken);
        // }
      },
      src: this.videoSrc,
    });

    // this.player?.play();

    this.player?.on(flowplayer.events.QUALITIES, () => {
      this.player?.emit(flowplayer.events.SET_QUALITY, {level: -1});
    });

    this.player?.on(flowplayer.events.SET_QUALITY, function (ev: any) {
      try {
        // console.log(ev.data.level, ev)
        // localStorage.previous_quality = ev.data.level;
      } catch (e) {
        // Localstorage not available
      }
    });

    this.player?.on(flowplayer.events.ERROR, (ev: any) => {
      // this.lastError = ev.type + ' ' + JSON.stringify(ev.data) + ' ' + ev.code;
      // alert('error: ' + [ev.message, ev.error, ev.data, ev.code].join(', '));

      /*ev.preventDefault();
      this.player?.setState(flowplayer.states.ERRORED, true);
      // @ts-ignore
      this.player?.emit(flowplayer.message.events.SHOW_MESSAGE, {message: this.translate.instant('live_stream.stream_end_err')});*/
    });
  }

  playPause() {
    const video: any = document.getElementById(this.elementId as string);
    if (video.paused) video.play();
    else video.pause();
  }

  makeBig() {
    const video: any = document.getElementById(this.elementId as string);
    video.width = 560;
  }

  makeSmall() {
    const video: any = document.getElementById(this.elementId as string);
    video.width = 320;
  }

  makeNormal() {
    const video: any = document.getElementById(this.elementId as string);
    video.width = 420;
  }

  skip(value: number) {
    let video: any = document.getElementById(this.elementId as string);
    video.currentTime += value;
  }

  restart() {
    let video: any = document.getElementById("my_video_1");
    video.currentTime = 0;
  }


}
