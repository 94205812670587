import {NgModule} from '@angular/core';
import {LayoutsModule} from './modules/layouts/layouts.module';
import {ThemeModule} from './modules/theme/theme.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FullCalendarModule} from '@fullcalendar/angular';
import { ToastrModule } from "ngx-toastr";
import { TranslateModule } from "@ngx-translate/core";
import { RelativeTimePipe } from "./modules/pipes/relative-time.pipe";
import { TimeFormatPipe } from "./modules/pipes/time-format.pipe";
import { ReadableNumberPipe } from "./modules/pipes/readable-number.pipe";
import { ShortTextDatePipe } from "./modules/pipes/short-text-date.pipe";
import { TextTruncatePipe } from "./modules/pipes/text-truncate.pipe";
import { ShortNumberPipe } from "./modules/pipes/short-number.pipe";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { FormatDurationPipe } from "./modules/pipes/format-duration.pipe";
import { TimeTextFormatPipe } from "./modules/pipes/time-text-format.pipe";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { RelativeTimeAfterPipe } from "./modules/pipes/relative-time-after.pipe";
import { BypassHtmlSanitizerPipe } from "./modules/pipes/bypass-html-sanitizer.pipe";
import { VariableReplacerPipe } from "./modules/pipes/variable-replacer.pipe";
import { PremiumAccessService } from "./services/premium-access.service";
import { PageRouteService } from "./services/page-route.service";
import { SharedBlogModule } from "./modules/shared-blog/shared-blog.module";
import {PastTimePipe} from './modules/pipes/pasttime.pipe';
import {DateTimePipe} from './modules/pipes/datetime.pipe';

@NgModule({
  imports: [
    LayoutsModule,
    ThemeModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ToastrModule.forRoot({
      progressBar: true,
      timeOut: 4000,
      easeTime: 200,
      maxOpened: 1,
      preventDuplicates: true,
      positionClass: 'toast-bottom-center'
    }),
    FullCalendarModule,
    NgxSkeletonLoaderModule.forRoot(),
    SweetAlert2Module.forRoot(),
    SharedBlogModule,
    // InfiniteScrollModule,
  ],
  declarations: [
    RelativeTimePipe,
    TimeFormatPipe,
    ReadableNumberPipe,
    ShortTextDatePipe,
    TextTruncatePipe,
    ShortNumberPipe,
    FormatDurationPipe,
    TimeTextFormatPipe,
    RelativeTimeAfterPipe,
    BypassHtmlSanitizerPipe,
    VariableReplacerPipe,
    PastTimePipe,
    DateTimePipe,
  ],
  providers: [
    PremiumAccessService,
    PageRouteService,
  ],
  exports: [
    LayoutsModule,
    ThemeModule,
    FormsModule,
    ReactiveFormsModule,
    FullCalendarModule,
    NgxSkeletonLoaderModule,
    // InfiniteScrollModule,
    RelativeTimePipe,
    TimeFormatPipe,
    ReadableNumberPipe,
    ShortTextDatePipe,
    TextTruncatePipe,
    ShortNumberPipe,
    FormatDurationPipe,
    TimeTextFormatPipe,
    RelativeTimeAfterPipe,
    BypassHtmlSanitizerPipe,
    VariableReplacerPipe,
    SweetAlert2Module,
    PastTimePipe,
    DateTimePipe,
  ],
})
export class SharedModule {}
