import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {DefaultLayoutComponent} from './layouts/default-layout/default-layout.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ThemeModule} from '../theme/theme.module';

@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, ThemeModule],
  declarations: [DefaultLayoutComponent],
  exports: [DefaultLayoutComponent],
})
export class LayoutsModule {}
