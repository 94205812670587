import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ILevel, ILevelExamScore } from "../../../../../../core/models/user/level.model";

@Component({
  selector: 'app-level-select-item',
  templateUrl: './level-select-item.component.html',
  styleUrl: './level-select-item.component.scss'
})
export class LevelSelectItemComponent {

  @Input() isSelected = false;
  @Input() score?: ILevelExamScore;
  @Input() level?: ILevel;
  @Output() clicked = new EventEmitter();

  constructor() {
  }

  click(){
    this.clicked.emit();
  }
}
