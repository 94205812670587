import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textTruncate'
})
export class TextTruncatePipe implements PipeTransform {

  transform(value: string, limit: number, ellipsis: boolean = true): string {
    if (!value) return '';
    if (value.length <= limit) return value;

    return value.substr(0, limit) + (ellipsis ? '...' : '');
  }

}
