import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-gray-button',
  templateUrl: './gray-button.component.html',
  styleUrls: ['./gray-button.component.scss'],
})
export class GrayButtonComponent  implements OnInit {
  @Input() text: string = 'Button';
  @Input() color: ('primary' | 'main' | 'secondary' | 'stroke' | 'stroke-light' | 'stroke-gray' | 'stroke-transparent' | 'transparent' | 'blue') = 'primary';
  @Input() weight = 800;
  @Input() padding = 'p-3';
  @Input() fullWidth = true;

  @Input() preIcon!: string;
  @Input() postIcon!: string;

  @Input() disabled: boolean = false;

  @Input() type: ('button' | 'submit') = 'button';

  @Input() customClass: string = '';
  @Input() isLoading = false;

  constructor() { }

  ngOnInit() {}

}
