import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'readableNumber'
})
export class ReadableNumberPipe implements PipeTransform {

  transform(value: number, locale: string = 'tr-TR'): string {
    // Use Intl.NumberFormat for locale-aware formatting
    // 'de-DE' is used as an example for locales that use dot as thousands separator
    const formatter = new Intl.NumberFormat(locale);
    return formatter.format(value);
  }

}
