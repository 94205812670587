import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'shortTextDate'
})
export class ShortTextDatePipe implements PipeTransform {

  transform(value: Date | string, locale = 'tr-TR'): string {
    const datePipe = new DatePipe(locale);
    return datePipe.transform(value, 'd MMM y', undefined, locale) || '';
  }

}
