<div #inputContainer class="input-container" [class.has-error]="hasErr">
  <div class="input-group">
    <div class="input-group-text" *ngIf="icon && icon !== ''">
      <gray-icon *ngIf="icon && icon !== ''" [icon]="icon"></gray-icon>
    </div>
    <input #inputElement class="form-control fs-large"
           [type]="type"
           autocapitalize="off"
           autocomplete="off"
           [value]="value"
           (focusin)="inputContainer.classList.add('active')"
           (focusout)="inputContainer.classList.remove('active')"
           [placeholder]="placeholder"
           (input)="onValueChange($event)">
    <!--(change)="onValueChange($event)">-->
    <span class="input-group-text">
      <gray-icon (click)="toggleVisibility()" icon="{{type === 'text' ? 'eye' : 'eye-slash'}}"></gray-icon>
    </span>
  </div>
</div>
