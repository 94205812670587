import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-gray-text-input',
  templateUrl: './gray-text-input.component.html',
  styleUrls: ['./gray-text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: GrayTextInputComponent
    },
  ]
})
export class GrayTextInputComponent implements ControlValueAccessor, OnInit {
  @Input() formControlName!: string; // <-- passing value from parent component
  readonly formControl: FormControl = new FormControl(); // <-- local FormControl
  @Input() label = '';
  @Input() type = 'text';
  @Input() placeholder: string = '';
  @Input() maskPrefix: string = '';
  @Input() mask?: string;
  @Input() hasErr: boolean = false;
  @Input() dropSpecialCharacters = true;


  @Input() icon!: string;
  @Input() isNumber = false;
  @Input() customClass = '';

  value: any = null;

  touched = false;
  disabled: boolean = false;

  private readonly destroyRef: DestroyRef = inject(DestroyRef); // <-- used for unsubscribing


  onChange = (value: any) => {
  };

  onTouched = () => {
  };
  //
  // onValueChange($event: Event & any) {
  //   const value: any = $event.target.value;
  //
  //   this.markAsTouched();
  //   if (!this.disabled) {
  //     this.value = this.isNumber ? Number(value) : value;
  //     this.onChange(this.value);
  //   }
  // }
  //
  // writeValue(value: any) {
  //   if (value !== undefined) {
  //     this.value = this.isNumber ? Number(value) : value;
  //   }
  // }
  //
  // registerOnChange(onChange: any) {
  //   this.onChange = onChange;
  // }
  //
  // registerOnTouched(onTouched: any) {
  //   this.onTouched = onTouched;
  // }
  //
  // markAsTouched() {
  //   if (!this.touched) {
  //     this.onTouched();
  //     this.touched = true;
  //   }
  // }
  //
  // setDisabledState(disabled: boolean) {
  //   this.disabled = disabled;
  // }

  ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val: any): void => this.onChange(this.isNumber ? Number(val) : val)); // <-- passing value to the parent form
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(val: any): void {
    this.formControl.patchValue(val, {emitEvent: false});
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    isDisabled ? this.formControl.disable() : this.formControl.enable();
  }

}
