import {Pipe, PipeTransform} from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'datetime'
})
export class DateTimePipe implements PipeTransform {
  transform(value: Date | string, arg: string = 'YYYY-MM-DD HH:mm:ss'): unknown {
    return dayjs(value).format(arg);
  }
}
