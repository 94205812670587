import { Component, OnInit } from '@angular/core';

import * as echarts from 'echarts';

@Component({
  selector: 'app-gray-weekly-plan',
  templateUrl: './gray-weekly-plan.component.html',
  styleUrls: ['./gray-weekly-plan.component.scss'],
})
export class GrayWeeklyPlanComponent  implements OnInit {

  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      this.initChart();
    });
  }

  private initChart() {
    const chartContainer = document.querySelector('.chart-container') as HTMLElement;
    const chart = echarts.init(chartContainer, undefined, {renderer: 'svg'});

    const chartOptions: echarts.EChartsOption = {
      xAxis: {
        type: 'category',
        data: ['PAZ', 'SAL', 'ÇAR', 'PER', 'CUM', 'CMT', 'PZR']
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          data: [120, 200, 150, 80, 70, 110, 130],
          type: 'bar',
          itemStyle: {
            color: '#ED79A2',
            borderRadius: [25, 25, 0, 0],
          },
          barWidth: 10,
        }
      ],
    };

    chart.setOption(chartOptions);
    window.onresize = () => {
      chart.resize();
    };
  }
}
