import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'timeTextFormat'
})
export class TimeTextFormatPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';
    const parts = value.split(':');
    if (parts.length >= 2) {
      const hours = parts[0];
      const minutes = parts[1];
      return `${hours}:${minutes}`; // Returns the time in 'HH:mm' format
    }
    return value; // Return the original value if the format does not match
  }

}
