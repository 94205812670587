import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-gray-widget-one',
  templateUrl: './gray-widget-one.component.html',
  styleUrls: ['./gray-widget-one.component.scss'],
})
export class GrayWidgetOneComponent  implements OnInit {
  @Input() icon!: string;

  @Input() subject!: string;
  @Input() value!: string;

  @Input() bgColor: ('pink'|'green') = 'pink';

  constructor() { }

  ngOnInit() {}

}
