<div class="modal-body">

  <div class="row">

    <div class="col-12 d-flex justify-content-center">
      <img class="sale-img" src="assets/img/sale-box2.png">
    </div>

    <div class="col-12 px-5 mt-3 d-flex justify-content-center">
      <p class="color-gray-950 fw-800 fs-20 text-center">{{'account.be_premium_desc'|translate}}</p>
    </div>

    <div class="col-12 mt-4 px-5 d-flex justify-content-center">
      <app-gray-button
        (click)="goToPayment()"
        [text]="'account.be_premium'|translate"
        padding="px-5 py-3" type="button"
        [fullWidth]="false"
        customClass="d-flex align-items-center justify-content-center"
        color="primary" preIcon="premium" [weight]="500"
      ></app-gray-button>
    </div>

    <div class="col-12 mt-3 d-flex justify-content-center">
      <a (click)="modal.close()" class="color-gray-300 fw-700 fs-normal clickable">
        {{'buttons.give_up'|translate}}
      </a>
    </div>
  </div>

</div>
