import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-gray-password-input',
  templateUrl: './gray-password-input.component.html',
  styleUrls: ['./gray-password-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: GrayPasswordInputComponent
    },
  ]
})
export class GrayPasswordInputComponent implements ControlValueAccessor {
  @ViewChild('inputContainer') inputContainer!: ElementRef<HTMLElement>;
  @ViewChild('inputElement') inputElement!: ElementRef<HTMLElement>;

  @Input() label = '';
  type: ('password'|'text') = 'password';
  @Input() placeholder: string = '';

  @Input() icon!: string;
  @Input() hasErr: boolean = false;

  value: any = null;

  touched = false;
  disabled = false;

  onChange = (value: any) => {
  };

  onTouched = () => {
  };

  onValueChange($event: Event & any) {
    const value: any = $event.target.value;

    this.markAsTouched();
    if (!this.disabled) {
      this.value = value;
      this.onChange(this.value);
    }
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.value = value;
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  clear() {
    this.markAsTouched();
    if (!this.disabled) {
      this.value = '';
      this.onChange(this.value);
    }
  }

  canClear() {
    return this.value.length >= 1;
  }

  loseFocus() {
    setTimeout(() => {
      this.inputContainer.nativeElement.classList.remove('active');
    }, 100);
  }

  toggleVisibility() {
    this.type = this.type === 'password' ? 'text' : 'password';

    this.inputElement.nativeElement.focus();
  }
}
