import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'variableReplacer'
})
export class VariableReplacerPipe implements PipeTransform {

  transform(value: string, variables: { [key: string]: any }): string {
    if (!value) return value;
    return value.replace(/\{\{(\w+)\}\}/g, (match, key) => {
      return variables.hasOwnProperty(key) ? variables[key] : match;
    });
  }
}
