import { Component, OnInit } from '@angular/core';
import { cdnUri } from "../../../../../core/helpers/app.helper";

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent  implements OnInit {
  appPages = [
    {
      title: 'Anasayfa',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Videolar',
      url: '/videos',
      icon: 'videos'
    },
    {
      title: 'Analiz',
      url: '/settings',
      icon: 'analysis'
    },
    {
      title: 'GriBlog',
      url: '/settings',
      icon: 'blog'
    },
    {
      title: 'Dökümanlar',
      url: '/settings',
      icon: 'document'
    },
    {
      title: 'NaviMentor',
      url: '/settings',
      icon: 'brain'
    },
    {
      title: 'Kendini Tanı',
      url: '/settings',
      icon: 'know-yourself'
    },
    {
      title: 'Hesap',
      url: '/account',
      icon: 'user'
    },
    {
      title: 'Canlı Yayınlar',
      url: '/streams',
      icon: 'map'
    },
  ];
  loggedIn = false;

  constructor() {}

  ngOnInit() {}

  protected readonly cdnUri = cdnUri;
}
